import * as React from "react";
import { Link } from "gatsby";

const MenuLinks = () => {
  return (
    <div className="flex flex-col space-y-10 absolute left-0 right-0 top-14 p-5 sm:p-10 bg-black lg:p-0 lg:static lg:top-0 lg:space-y-0 lg:flex-row lg:items-center lg:space-x-10 z-50">
      <Link to="/">
        <h5 className="text-lg">Overview</h5>
      </Link>
      <Link to="/partners">
        <h5 className="text-lg">Partners</h5>
      </Link>
      <Link to="/updates">
        <h5 className="text-lg">Updates</h5>
      </Link>
      <Link to="/plans">
        <h5 className="text-lg">Plans</h5>
      </Link>
      {/* <Link to="/blog">
        <h5 className="text-lg">Blog</h5>
      </Link> */}
      <Link to="/api_documentation">
        <h5 className="text-lg">Data API</h5>
      </Link>
      <Link to="https://help.petroninja.com">
        <h5 className="text-lg">Help Center</h5>
      </Link>
      <button className="outline-button">
        <Link to="https://petroninja.com">
          <h5>Try For Free</h5>
        </Link>
      </button>
    </div>
  );
};

export default MenuLinks;
