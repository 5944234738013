import * as React from "react";
import { Link } from "gatsby";

const PlansButton = ({ link, text, secondary }) => {
  if (link.startsWith("/")) {
    return (
      <div class="flex-shrink-0 pt-4">
        <Link to={link} className="hover:no-underline">
          <button
            class={`inline-flex items-center justify-center w-full max-w-xs px-4 py-3 transition-colors border rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
              secondary
                ? "hover:bg-red hover:text-white"
                : "bg-red text-white hover:bg-dark-red"
            }`}
          >
            {text}
          </button>
        </Link>
      </div>
    );
  }

  return (
    <div class="flex-shrink-0 pt-4">
      <a href={link} className="hover:no-underline">
        <button
          class={`inline-flex items-center justify-center w-full max-w-xs px-4 py-3 transition-colors border rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
            secondary
              ? "hover:bg-red hover:text-white"
              : "bg-red text-white hover:bg-dark-red"
          }`}
        >
          {text}
        </button>
      </a>
    </div>
  );
};

export default PlansButton;
