import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

import checkmark from '../images/checkmark.svg'

import SEO from "../components/seo";
import Layout from "../components/layout";
import Button from "../components/plans-button";

const Pricing = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "content/pricing/" } }
      ) {
        nodes {
          frontmatter {
            title
            price
            description
            features_list
            primary_button {
              title
              link
            }
            secondary_button {
              title
              link
            }
          }
        }
      }
    }
  `);

  const findPlan = (name) => {
    return data.allMarkdownRemark.nodes.find(
      (el) => el.frontmatter.title === name
    );
  };

  const starter = findPlan("Starter");
  const professional = findPlan("Professional");
  const enterprise = findPlan("Enterprise");

  console.log(professional)
  return (
    <>
      <SEO title="Plans" />
      <Layout>
        <main className="max-w-container flex flex-col space-y-10 py-20 px-10 lg:px-0 mx-auto">
          <div className="flex flex-col items-center justify-center space-y-8 lg:flex-row lg:items-stretch lg:space-x-8 lg:space-y-0">
            <section className="flex flex-col w-full max-w-sm p-12 space-y-6 bg-white rounded-lg shadow-md h-full justify-between min-h-pricing-container">
              <div>
                <div className="flex-shrink-0 mb-4">
                  <span className="text-4xl font-medium tracking-tight">
                    ${starter.frontmatter.price}
                  </span>
                  <span className="text-gray-400"> /month</span>
                </div>
                <div className="flex-shrink-0 pb-6 space-y-2 border-b">
                  <h2 className="text-2xl font-bold">
                    {starter.frontmatter.title}
                  </h2>
                  <p className="text-sm text-gray-400 ">
                    {starter.frontmatter.description}
                  </p>
                </div>
                <ul className="flex-1 space-y-4 pt-6">
                  {starter.frontmatter.features_list.map((feature, i) => (
                    <li className="flex items-start" key={i}>
                      <div>
                        <img src={checkmark} alt="checkmark" className="w-6 min-6"/>
                      </div>
                      <span className="ml-3 text-base font-medium">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="flex justify-center">
                <div className="w-full">
                  <Button
                    text={starter.frontmatter.primary_button.title}
                    link={starter.frontmatter.primary_button.link}
                  />
                </div>
              </div>
            </section>
            <section className="flex flex-col w-full max-w-sm p-12 space-y-6 bg-white rounded-lg shadow-md h-full justify-between min-h-pricing-container">
              <div>
                <div className="flex-shrink-0 mb-4">
                  <span className="text-4xl font-medium tracking-tight">
                    ${professional.frontmatter.price}
                  </span>
                  <span className="text-gray-400">
                    {" "}
                    /user/month
                  </span>
                </div>
                <div className="flex-shrink-0 pb-6 space-y-2 border-b">
                  <h2 className="text-2xl font-bold">
                    {professional.frontmatter.title}
                  </h2>

                  <p className="text-sm text-gray-400 ">
                    {professional.frontmatter.description}
                  </p>
                </div>
                <ul className="flex-1 space-y-4 pt-6">
                  {professional.frontmatter.features_list.map((feature, i) => (
                    <li className="flex items-start" key={i}>
                      <div>
                        <img src={checkmark} alt="checkmark" className="w-6 min-w-6"/>
                      </div>
                      <span
                        className="ml-3 text-base font-medium"
                        dangerouslySetInnerHTML={{ __html: feature }}
                      />
                    </li>
                  ))}
                </ul>
              </div>

              <div className="w-full">
                <Button
                  text={professional.frontmatter.primary_button.title}
                  link={professional.frontmatter.primary_button.link}
                />
              </div>
            </section>
            <section className="flex flex-col w-full max-w-sm p-12 space-y-6 bg-white rounded-lg shadow-md h-full justify-between min-h-pricing-container">
              <div>
                <div className="flex-shrink-0 mb-8  ">
                  <div className="text-gray-400 font-bold">
                    Contact us:{" "}
                    <span className="font-normal">hello@stackdx.com</span>
                  </div>
                </div>
                <div className="flex-shrink-0 pb-6 space-y-2 border-b">
                  <h2 className="text-2xl font-bold">
                    {enterprise.frontmatter.title}
                  </h2>
                  <p className="text-sm text-gray-400">
                    {enterprise.frontmatter.description}
                  </p>
                </div>
                <ul className="flex-1 space-y-4 pt-6">
                  {enterprise.frontmatter.features_list.map((feature, i) => (
                    <li className="flex items-start" key={i}>
                      <div>
                        <img src={checkmark} alt="checkmark" className="w-6 min-w-6"/>
                      </div>
                      <span
                        className="ml-3 text-base font-medium"
                        dangerouslySetInnerHTML={{ __html: feature }}
                      />
                    </li>
                  ))}
                </ul>
              </div>

              <div className="w-full">
                <Button
                  text={enterprise.frontmatter.primary_button.title}
                  link={enterprise.frontmatter.primary_button.link}
                  secondary
                />
              </div>
            </section>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default Pricing;
