import * as React from "react";
import { Link } from "gatsby";

import { StaticImage } from "gatsby-plugin-image";

const Footer = () => {
  return (
    <footer>
      <div className="w-full max-w-container flex justify-between mx-5 sm:mx-10 flex-col-reverse lg:flex-row">
        <div className="flex flex-col mt-20 lg:mt-0 space-y-10 lg:justify-between">
          <div className="w-48">
            <StaticImage
              src="../images/StackDxLogoBlackText.svg"
              alt="Footer Logo"
              width={175}
            />
          </div>

          <span className="footer-text">
            Stack Technologies Ltd.
            <br />
            is a privately held technology company in Calgary, AB, Canada
            <br />
            <br />© 2024 Stack Technologies Ltd. •{" "}
            <Link to="https://petroninja.com/terms" className="underline">
              Terms
            </Link>{" "}
            •{" "}
            <Link to="https://petroninja.com/privacy" className="underline">
              Privacy
            </Link>
          </span>
        </div>

        <div className="flex flex-col space-y-10 lg:space-y-0 lg:flex-row">
          <div className="flex flex-col space-y-8 pr-10 border-b pb-10 lg:pb-0 lg:border-b-0 lg:border-r border-white">
            <div className="flex flex-row space-x-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="21.947"
                viewBox="0 0 16.079 21.947"
              >
                <g id="noun_Address_2340040" transform="translate(-15.2 -2.5)">
                  <g
                    id="Group_73"
                    data-name="Group 73"
                    transform="translate(15.2 2.5)"
                  >
                    <path
                      id="Path_37"
                      data-name="Path 37"
                      d="M27.467,67.6c-.277.485-.578.947-.878,1.386,1.941.439,3.1,1.2,3.1,1.779,0,.855-2.564,2.149-6.446,2.149s-6.446-1.294-6.446-2.149c0-.578,1.155-1.317,3.1-1.779-.3-.439-.6-.9-.878-1.386-2.218.578-3.812,1.617-3.812,3.165,0,2.449,4.043,3.743,8.04,3.743s8.04-1.294,8.04-3.743C31.279,69.217,29.685,68.178,27.467,67.6Z"
                      transform="translate(-15.2 -52.56)"
                      fill="#fff"
                    />
                    <path
                      id="Path_38"
                      data-name="Path 38"
                      d="M29.723,20.058c1.64-2.426,5.568-8.5,5.568-11.251a6.3,6.3,0,1,0-12.591,0c0,2.749,3.95,8.825,5.591,11.251A.887.887,0,0,0,29.723,20.058ZM26.073,8.807a2.934,2.934,0,1,1,2.934,2.911A2.938,2.938,0,0,1,26.073,8.807Z"
                      transform="translate(-20.967 -2.5)"
                      fill="#fff"
                    />
                  </g>
                </g>
              </svg>

              <span className="footer-text">
                639 5th Ave SW, Suite 1100
                <br />
                Calgary, AB
                <br />
                Canada, T2P 0M9
              </span>
            </div>
            <div className="flex flex-row space-x-4 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="12.106"
                viewBox="0 0 18.159 12.106"
              >
                <g id="noun_envelope_71773" transform="translate(0 -16)">
                  <path
                    id="Path_35"
                    data-name="Path 35"
                    d="M13.184,22.369,20.544,16H3.536l7.179,6.371a1.963,1.963,0,0,0,2.469,0Z"
                    transform="translate(-2.867)"
                    fill="#fff"
                  />
                  <path
                    id="Path_36"
                    data-name="Path 36"
                    d="M10.812,24.737a2.72,2.72,0,0,1-3.465,0L0,18.216V29.525a.378.378,0,0,0,.378.378h17.4a.379.379,0,0,0,.378-.378V18.38Z"
                    transform="translate(0 -1.797)"
                    fill="#fff"
                  />
                </g>
              </svg>

              <span className="underline footer-text">
                <a href="mailto:hello@stackdx.com">hello@stackdx.com</a>
              </span>
            </div>
          </div>
          <div className="flex flex-col space-y-2 lg:pl-10 font-bold footer-text">
            <Link to="/">Overview</Link>
            <Link to="/partners">Partners</Link>
            <Link to="https://petroninja.com">Login</Link>
            <Link to="/contact">Request a Demo</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
