import React, { useState } from "react";
import { Link } from "gatsby";
import MenuLinks from "./menu-links";
import Hamburger from "../images/hamburger.svg";
import Close from "../images/close.svg";

import { StaticImage } from "gatsby-plugin-image";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <header className="relative">
      <div className="lg:px-0 w-full container max-w-container mx-5 sm:mx-10 flex items-center justify-center lg:justify-between py-5 ">
        <button
          className="flex lg:hidden absolute left-5 sm:left-10 cursor-pointer"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          {menuOpen ? (
            <img width="20" height="20" src={Close} alt="Close menu" />
          ) : (
            <img width="24" height="19" src={Hamburger} alt="Hamburger menu" />
          )}
        </button>
        {menuOpen && (
          <div className="lg:hidden ">
            <MenuLinks />
          </div>
        )}

        <span className="font-bold text-logo-mobile lg:text-logo lg:font-normal">
          <Link to="/" className="hover:no-underline">
            <StaticImage
              src="../images/logo.png"
              alt="Logo"
              width={175}
            />
          </Link>
        </span>

        <nav className="hidden lg:flex">
          <MenuLinks />
        </nav>
      </div>
    </header>
  );
};

export default Header;
